import {makeObservable, observable} from "mobx";
import {inject, observer} from "mobx-react";
import * as React from "react";

import {IScoreboard, ITask} from "@store/CtfStore";
import {IRootStore} from "@store/index";

import Footer from "@components/Footer";
import Loader from "@components/Loader";
import Timer from "@components/Timer";

import "@styles/scoreboard.scss";
import ScoreChart, { ScoreChartLegend } from "@components/ScoreChart";

interface IComputedScoreboard {
    sortedCategories: Map<string, {
        first_task_id: number;
        category: string;
        count: number;
    }>;
    sortedTasks: ITask[];
    sortedRanking: IScoreboard[];
    taskSolvedTeams: Map<number, Array<{
       team_id: number;
       created_at: Date;
    }>>;
    maxRanking: number;
}


@inject("store")
@observer
export class ScoreboardGraphPage extends React.Component<IScoreboardGraphPageProps, {}> {
    @observable public rankingOffset: number = 20;

    constructor(props: IScoreboardGraphPageProps) {
        super(props);
        makeObservable(this);
    }

    public async componentDidMount() {
        await Promise.all([
            this.props.store.ctf.fetchTasks(),
            this.props.store.ctf.fetchScoreboard(),
            this.props.store.ctf.fetchInfo(),
        ]);
    }

    public trunc( text: string, length: number ) {
        return (text.length > length) ? text.substr(0, length - 1) + "..." : text;
    }

    public computedScoreboard(): IComputedScoreboard {
        const sortedCategories: Map<string, {
            first_task_id: number;
            category: string;
            count: number;
        }> = new Map();
        const taskSolvedTeams: Map<number, Array<{
            team_id: number;
            created_at: Date;
        }>> = new Map();

        const sortedRanking = Array.from(this.props.store.ctf.scoreboard.values());
        const sortedTasks = Array.from(this.props.store.ctf.tasks.values()).sort((a, b) => {
            if (a.api.categories[0].name < b.api.categories[0].name) {
                return -1;
            }
            if (a.api.categories[0].name > b.api.categories[0].name) {
                return 1;
            }

            if (a.api.id < b.api.id) {
                return -1;
            }
            if (a.api.id > b.api.id) {
                return 1;
            }

            return 0;
        });
        for (const task of sortedTasks) {
            const cat = task.api.categories[0];
            const g = sortedCategories.get(cat.id);
            sortedCategories.set(cat.id, {
                category: cat.name,
                count: ((g && g.count) || 0) + 1,
                first_task_id: ((g && g.first_task_id) || task.id),
            });
        }
        for (const scoreboard of sortedRanking) {
            for (const task of scoreboard.api.team.task_solved) {
                if (!taskSolvedTeams.has(task.id)) {
                    taskSolvedTeams.set(task.id, []);
                }
                const g = taskSolvedTeams.get(task.id);
                g && g.push({
                    team_id: scoreboard.api.team.id,
                    created_at: task.created_at,
                });
            }
        }
        for (const key of taskSolvedTeams.keys()) {
            const g = taskSolvedTeams.get(key);
            g && g.sort((a, b) => {
                return (+a.created_at) - (+b.created_at);
            });
        }
        return {
            sortedCategories,
            sortedTasks,
            sortedRanking: sortedRanking.slice(0, this.rankingOffset),
            taskSolvedTeams,
            maxRanking: sortedRanking.length,
        };
    }

    public onLoadMore = () => {
        this.rankingOffset = this.rankingOffset + 20;
    }

    public render() {
        return (
            <div className={"page scoreboard"}>
                <div className={"inner"}>
                    <h1 className={"mainTitle"}>Scoreboard</h1>

                    {(this.props.store.ctf.tasksState === "pending" || this.props.store.ctf.scoreboardState === "pending") && <Loader text={"Loading scoreboard"} />}
                    {(this.props.store.ctf.tasksState === "error" || this.props.store.ctf.scoreboardState === "error") && <Loader text={"Error during loading scoreboard"} />}
                    {this.props.store.ctf.tasksState === "done" && this.props.store.ctf.scoreboardState === "done" && (<>
                        {this.props.store.ctf.scoreboardIsFreeze && (
                            <>
                                <h4>Scoreboard has been frozen.</h4>
                                <span className={"unfreeze"}>unfreezed in</span>
                                <Timer date={this.props.store.ctf.info.end}/>
                            </>
                        )}

                        <div className={"chart-container"}>
                            <div className={"chart"}>
                                <ScoreChart
                                    ctfInfo={this.props.store.ctf.info}
                                    scoreboardData={this.props.store.ctf.scoreboard}
                                    tasks={this.props.store.ctf.tasks} />
                            </div>
                            <ScoreChartLegend
                                scoreboardData={this.props.store.ctf.scoreboard} />
                        </div>
                    </>)}

                    <Footer />
                </div>
            </div>
        );
    }
}

interface IScoreboardGraphPageProps {
    store: IRootStore;
}

const MIN_POINTS = 50;
const MAX_POINTS = 500;
const SLOPE = 190;

function calculateTaskPoints(solves: number) {
    return Math.max(MIN_POINTS, Math.floor(MAX_POINTS - SLOPE * Math.log2(
        (Math.max(1, solves) + 3) / (1 + 3)
    )));
}

const pointsBySolves: number[] = [];
for (let i = 0; ; i++) {
    const points = calculateTaskPoints(i);
    if (points === MIN_POINTS)
        break;

    pointsBySolves.push(points);
}

export function getTaskPoints(solves: number) {
    return pointsBySolves[solves] || MIN_POINTS;
}

import * as React from "react";

import Footer from "@components/Footer";

import "@styles/rules.scss";

import {competitionVersion} from "@consts/index";

export const RulesPage: React.FunctionComponent = ( ) => (
    <div className={"page rules"}>
        <div className={"inner"}>
            <h1 className={"mainTitle"}>Rules</h1>

            <ol>
                <li>Each team is allowed to participate under one account and each member must belong to exactly one team. Teams may consist up to four members.</li>
                <li>During the contest, sharing flags, solutions, hints or asking for help outside the team is prohibited. Do not post or share any challenge-related information publicly during the competition.</li>
                <li>If you have questions about challenges or you believe that you found a correct flag, but the system is not accepting it, ask us through the <a href="/threads">Messages tab "ask" button</a> or via e-mail <a href={"mailto:justcatthefish+"+competitionVersion+"@gmail.com"}>justcatthefish+{competitionVersion}@gmail.com</a>. <b>Do not brute-force flag validation endpoint.</b></li>
                <li>Attacking the infrastructure, other teams or any attempt to disrupt the competition is prohibited.</li>
                <li>Please, report any bugs you find in the infrastructure or tasks directly to the organizers.</li>
                <li><b>Breaking any of the above rules may result in team disqualification.</b></li>
                <li>We have a custom dynamic scoring system, which means that the challenge’s points depend on the number of its solves.</li>
                <li>All flags fall into the following format: <code>{"justCTF{something_h3re!}"}</code>, unless the challenge description states otherwise.</li>
                <li>Challenges might be released at different times, but it is guaranteed that all of them will be released no later than 10 hours before the end of the competition.</li>
                <li>All crucial information about challenges or the competition will be announced in the news section on the CTF page and on the corresponding channel on the live chat.                </li>
                <li>Registration will be open before the competition.</li>
                <li>The competition will last for 24 hours straight.</li>
                <li>The presented set of rules might change before the start of the competition.</li>
                <li>While we don't prohibit participation from anywhere in the world, we will not be able to send prizes to individuals from countries <a href="https://www.gov.pl/web/finanse/sankcje-miedzynarodowe-giif">sanctioned in Poland (or UE, UN etc.)</a>.</li>
                <li>Each participant of a Polish Academic Team may be required to provide proof of enrollment at a Polish university or other educational institution in order to receive a prize.</li>
                <li>Resolving any unregulated cases remains on organizers' discretion.</li>
            </ol>

            <Footer />
        </div>
    </div>
);

import {inject, observer} from "mobx-react";
import {RouterStore} from "mobx-react-router";
import * as React from "react";

import {
    AnnouncementsPage,
    ChallengesPage,
    HomePage,
    LoginPage,
    NotFoundPage,
    RecoverInitPage,
    RecoverSetPasswordPage,
    RegisterPage,
    RulesPage,
    FaqPage,
    ScoreboardPage,
    ScoreboardGraphPage,
    ScoreboardListPage,
    SettingsPage,
    TeamPage,
    TeamsPage,
    UnavailablePage,
    ThreadsPage,
    ThreadView
} from "../pages";
import {competitonLive, archived} from "@consts/index";

const Container: React.FunctionComponent<IContainerProps> = ( { routing}: IContainerProps ) => {
    if ( !routing ) {
        return null;
    }

    const { location, push } = routing;

    const componentDict: { [key: string]: any; } = {
        "/": HomePage,
        "/challenges": (competitonLive || archived) ? ChallengesPage : UnavailablePage,
        "/scoreboard": (competitonLive || archived) ? ScoreboardPage : UnavailablePage,
        "/scoreboard_graph": (competitonLive || archived) ? ScoreboardGraphPage : UnavailablePage,
        "/scoreboard_list": (competitonLive || archived) ? ScoreboardListPage : UnavailablePage,
        "/rules": RulesPage,
        "/faq": FaqPage,
        "/teams": (competitonLive || archived) ? TeamsPage : UnavailablePage,
        "/news": (competitonLive || archived) ? AnnouncementsPage : UnavailablePage,
        "/threads": competitonLive ? ThreadsPage : UnavailablePage,
        "/settings": competitonLive ? SettingsPage : UnavailablePage,
        "/login": competitonLive ? LoginPage : UnavailablePage,
        "/register": competitonLive ? RegisterPage : UnavailablePage,
        "/recover/init": competitonLive ? RecoverInitPage : UnavailablePage,
        "/recover/set_password": competitonLive ? RecoverSetPasswordPage : UnavailablePage,
        "/404": NotFoundPage,
    };

    const teamRegex = new RegExp(`^\/team\/(\\d+)$`);

    if ( teamRegex.test(location.pathname) ) {
        const matches = teamRegex.exec( location.pathname );

        if ( matches ) {
            return <TeamPage id={parseInt(matches[ 1 ], 10)} />;
        }
    }

    const threadRegex = new RegExp(`^\/thread\/(\\d+)$`);

    if ( threadRegex.test(location.pathname) ) {
        const matches = threadRegex.exec( location.pathname );

        if ( matches ) {
            return <ThreadView threadId={parseInt(matches[ 1 ], 10)} />;
        }
    }

    const challangeRegex = new RegExp(`^\/challenges\/(\\d+)$`);

    if ( challangeRegex.test(location.pathname) ) {
        const matches = challangeRegex.exec( location.pathname );

        if ( matches ) {
            return <ChallengesPage task_id={parseInt(matches[ 1 ], 10)} />;
        }
    }

    let ComponentTag = componentDict[location.pathname];

    if (!ComponentTag) {
        push("/404");
        ComponentTag = componentDict["/404"];
    }

    return <ComponentTag />;

};

interface IContainerProps {
    routing?: RouterStore;
}

export default inject("routing")(observer(Container));
